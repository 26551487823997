import { HTMLAttributes } from "react";
import BaseSection from "../components/BaseSection";
import { TechImage } from "./TechImage";

function TechnologySection({ ...attrs }: HTMLAttributes<HTMLElement>) {
  return (
    <BaseSection
      title="Tech"
      {...attrs}
    >
      <div className="grid grid-cols-[repeat(auto-fit,minmax(80px,max-content))] md:justify-start justify-center gap-8">
        <TechImage
          size="large"
          href="https://www.android.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-original.svg"
          value="Android"
        />
        <TechImage
          size="large"
          href="https://kotlinlang.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/kotlin/kotlin-original.svg"
          value="Kotlin"
        />
        <TechImage
          size="large"
          href="https://www.postgresql.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg"
          value="SQL"
        />
        <TechImage
          size="large"
          href="https://www.typescriptlang.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"
          value={"Type\u00ADscript"}
        />
      </div>
      <hr className="border-dashed border-t-2 my-8 break-before-avoid break-after-avoid" />
      <div className="grid grid-cols-[repeat(auto-fit,minmax(80px,1fr))] md:justify-start justify-center gap-x-2 gap-y-8">
        <TechImage
          size="small"
          href="https://azure.microsoft.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/azure/azure-original.svg"
          value="Azure"
        />
        <TechImage
          size="small"
          href="https://bun.sh/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/bun/bun-original.svg"
          value="Bun"
        />
        <TechImage
          size="small"
          href="https://docs.microsoft.com/en-us/dotnet/csharp/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg"
          value="C#"
        />
        <TechImage
          size="small"
          href="https://developer.mozilla.org/en-US/docs/Web/CSS"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg"
          value="CSS"
        />
        <TechImage
          size="small"
          href="https://dart.dev/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dart/dart-original.svg"
          value="Dart"
        />
        <TechImage
          size="small"
          href="https://www.docker.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg"
          value="Docker"
        />
        <TechImage
          size="small"
          href="https://www.figma.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg"
          value="Figma"
        />
        <TechImage
          size="small"
          href="https://firebase.google.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/firebase/firebase-original.svg"
          value="Firebase"
        />
        <TechImage
          size="small"
          href="https://flutter.dev/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg"
          value="Flutter"
        />
        <TechImage
          size="small"
          href="https://git-scm.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"
          value="Git"
        />
        <TechImage
          className="dark:bg-white dark:print:bg-none dark:rounded-full dark:print:rounded-none dark:p-1 dark:print:p-0"
          size="small"
          href="https://github.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg"
          value="GitHub"
        />
        <TechImage
          size="small"
          href="https://gitlab.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/gitlab/gitlab-original.svg"
          value="GitLab"
        />
        <TechImage
          className="dark:bg-white dark:print:bg-none dark:rounded-md dark:print:rounded-none dark:p-1 dark:print:p-0"
          size="small"
          href="https://gradle.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/gradle/gradle-original.svg"
          value="Gradle"
        />
        <TechImage
          size="small"
          href="https://graphql.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/graphql/graphql-plain.svg"
          value="GraphQL"
        />
        <TechImage
          size="small"
          href="https://developer.mozilla.org/en-US/docs/Glossary/HTML5"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"
          value="HTML"
        />
        <TechImage
          size="small"
          href="https://www.java.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg"
          value="Java"
        />
        <TechImage
          size="small"
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
          value={"Java\u00ADscript"}
        />
        <TechImage
          className="dark:p-1 dark:bg-white dark:print:bg-none dark:rounded-full dark:print:rounded-none"
          size="small"
          href="https://www.latex-project.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/latex/latex-original.svg"
          value="LaTeX"
        />
        <TechImage
          size="small"
          href="https://en.wikipedia.org/wiki/Linux"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg"
          value="Linux"
        />
        <TechImage
          size="small"
          href="https://nextjs.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nextjs/nextjs-original.svg"
          value="NextJS"
        />
        <TechImage
          size="small"
          href="https://nodejs.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg"
          value="NodeJS"
        />
        <TechImage
          size="small"
          href="https://www.openapis.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/openapi/openapi-original.svg"
          value={"Open\u00ADAPI"}
        />
        <TechImage
          size="small"
          href="https://www.php.net/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg"
          value="PHP"
        />
        <TechImage
          size="small"
          href="https://www.python.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg"
          value="Python"
        />
        <TechImage
          size="small"
          href="https://reactjs.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
          value="React"
        />
        <TechImage
          className="dark:p-1 dark:bg-white dark:print:bg-none dark:rounded-full dark:print:rounded-none"
          size="small"
          href="https://www.rust-lang.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/rust/rust-original.svg"
          value="Rust"
        />
        <TechImage
          size="small"
          href="https://sass-lang.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg"
          value="Sass"
        />
        <TechImage
          size="small"
          href="https://tailwindcss.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tailwindcss/tailwindcss-original.svg"
          value={"tailwind\u00ADcss"}
        />
        <TechImage
          className="dark:invert dark:print:filter-none"
          size="small"
          href="https://vercel.com/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/vercel/vercel-original.svg"
          value={"Vercel"}
        />
        <TechImage
          size="small"
          href="https://webpack.js.org/"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/webpack/webpack-original.svg"
          value={"Web\u00ADpack"}
        />
      </div>
      <div className="clear-both"></div>
    </BaseSection>
  );
}

export default TechnologySection;
